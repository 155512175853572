import { gql } from "@apollo/client";

export const GetParkingAreasQuery = gql`
  query area($user: String, $status: [String], $page: Int) {
    parkingAreas(user: $user, status_code_list: $status, page: $page) {
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
      collection {
        id
        name
        category {
          code
        }
        status {
          code
        }
        description
        isAllowedInvitation
        areaType {
          code
        }
        isAllowedDemo
        parkingActivePlans {
          collection {
            vehicle {
              code
            }
            priceType {
              code
            }
            price
          }
        }
        totalSpot
        starRatings {
          collection {
            ques1Rating
            ques2Rating
            ques3Rating
            ques4Rating
          }
          paginationInfo {
            totalCount
            lastPage
          }
        }
        parkingMedia {
          collection {
            mediaUrl
            type
            id
            name
          }
        }
        lat
        long
        parkingAreaAddresses {
          collection {
            id
            address
            latitude
            longitude
            longitude
            description
            city {
              name
            }
            state {
              name
            }
            country {
              name
            }
            postalCode {
              pincode
            }
          }
        }
      }
    }
  }
`;

export const GetParkingAreaQuery = gql`
  query area($id: ID!) {
    parkingArea(id: $id) {
      id
      name
      lat
      long
      status {
        code
      }
      description
      isAllowedInvitation
      isAllowedPvtInvitation
      areaType {
        code
      }
      parkingActiveTimes {
        collection {
          id
          timeFrom
          timeTo
          isFullDay
          day {
            id
            code
          }
        }
      }
      parkingActivePlans {
        collection {
          id
          vehicle {
            code
          }
          priceType {
            code
          }
          price
        }
      }

      parkingAvailabilities {
        collection {
          id
          fromDate
          toDate
          status {
            code
          }
        }
      }
      totalSpot
      parkingMedia {
        collection {
          id
          mediaUrl
          mediaName {
            type
            code
          }
        }
      }
      parkingAreaAddresses {
        collection {
          id
          address
          latitude
          longitude
          longitude
          timeZoneOffset
          description
          city {
            id
            name
          }
          state {
            id
            name
          }
          country {
            id
            name
          }
          postalCode {
            id
            pincode
          }
        }
      }
    }
  }
`;

export const CreateParkingAreaQuery = gql`
  mutation createArea(
    $name: String!
    $description: String!
    $isAllowedInvitation: Boolean!
    $isAllowedDemo: Boolean!
    $areaType: String!
    $category: String!
    $userType: String!
  ) {
    createParkingArea(
      input: {
        name: $name
        description: $description
        isAllowedInvitation: $isAllowedInvitation
        isAllowedDemo: $isAllowedDemo
        areaType: $areaType
        userType: $userType
        category: $category
      }
    ) {
      parkingArea {
        id
        name
        description
      }
    }
  }
`;

export const UpdateParkingAreaQuery = gql`
  mutation updateParkingArea(
    $id: ID!
    $name: String
    $description: String
    $isAllowedInvitation: Boolean
    $isAllowedDemo: Boolean
    $areaType: String
    $isAllowedPvtInvitation: Boolean
  ) {
    updateParkingArea(
      input: {
        id: $id
        name: $name
        description: $description
        isAllowedInvitation: $isAllowedInvitation
        isAllowedDemo: $isAllowedDemo
        areaType: $areaType
        isAllowedPvtInvitation: $isAllowedPvtInvitation
      }
    ) {
      parkingArea {
        id
        name
        description
      }
    }
  }
`;

export const CreateParkingAreaAddressQuery = gql`
  mutation parkingAreaAddress(
    $parkingArea: String!
    $address: String!
    $latitude: String!
    $longitude: String!
    $city: String!
    $state: String!
    $postalCode: String!
  ) {
    createParkingAreaAddress(
      input: {
        parkingArea: $parkingArea
        address: $address
        latitude: $latitude
        longitude: $longitude
        city: $city
        state: $state
        postalCode: $postalCode
        country: "countries/107"
      }
    ) {
      parkingAreaAddress {
        id
        address
      }
    }
  }
`;

export const UpdateParkingAreaAddressQuery = gql`
  mutation parkingAreaAddress(
    $id: ID!
    $address: String!
    $latitude: String
    $longitude: String
    $city: String
    $state: String
    $postalCode: String
  ) {
    updateParkingAreaAddress(
      input: {
        id: $id
        address: $address
        latitude: $latitude
        longitude: $longitude
        city: $city
        state: $state
        postalCode: $postalCode
      }
    ) {
      parkingAreaAddress {
        id
        address
      }
    }
  }
`;

export const CreateAreaPriceQuery = gql`
  mutation createPrice(
    $parkingArea: String!
    $vehicel: String!
    $priceType: String!
    $price: Float!
  ) {
    createParkingActivePlan(
      input: {
        parkingArea: $parkingArea
        vehicle: $vehicel
        priceType: $priceType
        price: $price
      }
    ) {
      parkingActivePlan {
        id
        vehicle {
          code
        }
        price
        priceType {
          code
        }
      }
    }
  }
`;

export const UpdateAreaPriceQuery = gql`
  mutation updateAreaPrice(
    $id: ID!
    $vehicle: String
    $priceType: String
    $price: Float
  ) {
    updateParkingActivePlan(
      input: {
        id: $id
        vehicle: $vehicle
        priceType: $priceType
        price: $price
      }
    ) {
      parkingActivePlan {
        id
        vehicle {
          code
        }
        priceType {
          code
        }
        price
      }
    }
  }
`;

export const DeleteAreaPriceQuery = gql`
  mutation DeleteParkingActivePlan($id: ID!) {
    deleteParkingActivePlan(input: { id: $id }) {
      parkingActivePlan {
        id
      }
    }
  }
`;

export const CreateParkingActiveTime = gql`
  mutation ParkingActiveTimes(
    $parkingArea: String!
    $day: String!
    $timeFrom: String!
    $timeTo: String!
    $isFullDay: Boolean!
  ) {
    createParkingActiveTime(
      input: {
        day: $day
        timeFrom: $timeFrom
        timeTo: $timeTo
        isFullDay: $isFullDay
        parkingArea: $parkingArea
      }
    ) {
      parkingActiveTime {
        id
        timeFrom
        timeTo
        day {
          id
          code
        }
        isFullDay
      }
    }
  }
`;

export const UpdateParkingActiveTime = gql`
  mutation ParkingActiveTimes(
    $id: ID!
    $day: String
    $timeFrom: String
    $timeTo: String
    $isFullDay: Boolean
  ) {
    updateParkingActiveTime(
      input: {
        id: $id
        day: $day
        timeFrom: $timeFrom
        timeTo: $timeTo
        isFullDay: $isFullDay
      }
    ) {
      parkingActiveTime {
        id
        timeFrom
        timeTo
        day {
          code
          id
        }
        isFullDay
      }
    }
  }
`;

export const DeleteParkingAreaActiveTimeQuery = gql`
  mutation deleteParkingActiveTime($id: ID!) {
    deleteParkingActiveTime(input: { id: $id }) {
      parkingActiveTime {
        id
      }
    }
  }
`;

export const GetParkingAreasNameId = gql`
  query area($user: String, $status: [String], $category: [String]) {
    parkingAreas(
      user: $user
      status_code_list: $status
      category_code_list: $category
    ) {
      collection {
        id
        name
        status {
          code
        }
      }
    }
  }
`;

export const GetUserAreasQuery = gql`
  query GetUserAreas(
    $id: ID!
    $areaStatus: [String]
    $manageStatus: String
    $page: Int
  ) {
    user(id: $id) {
      id
      parkingAreas(status_code_list: $areaStatus, page: $page) {
        paginationInfo {
          lastPage
        }
        collection {
          id
          name
          isAllowedInvitation
          isAllowedPvtInvitation
          category {
            code
          }
          status {
            code
          }
          areaType {
            code
          }
          totalSpot
          starRatings {
            collection {
              ques1Rating
              ques2Rating
              ques3Rating
              ques4Rating
            }
            paginationInfo {
              totalCount
            }
          }
          parkingMedia {
            collection {
              id
              mediaUrl
              name
              mediaName {
                type
                code
              }
            }
          }
          parkingAreaAddresses {
            collection {
              id
              address
              city {
                name
              }
              state {
                name
              }
              postalCode {
                pincode
              }
            }
          }
        }
      }
      parkingAreaManagers(status_code: $manageStatus) {
        paginationInfo {
          lastPage
        }
        collection {
          id
          role
          parkingArea {
            id
            name
            category {
              code
            }
            status {
              code
            }
            areaType {
              code
            }
            totalSpot
            starRatings {
              collection {
                ques1Rating
                ques2Rating
                ques3Rating
                ques4Rating
              }
              paginationInfo {
                totalCount
                lastPage
              }
            }
            parkingMedia {
              collection {
                id
                mediaUrl
                name
                mediaName {
                  type
                  code
                }
              }
            }
            parkingAreaAddresses {
              collection {
                id
                address
                city {
                  name
                }
                state {
                  name
                }
                postalCode {
                  pincode
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CreateNoticeQuery = gql`
  mutation CreateNotice(
    $subject: String!
    $content: String!
    $parkingArea: String!
  ) {
    createNoticeBoard(
      input: { subject: $subject, content: $content, parkingArea: $parkingArea }
    ) {
      noticeBoard {
        id
        subject
        content
        createdAt
        admin {
          id
          name
          contactNumber
        }
      }
    }
  }
`;

export const UpdateNoticeQuery = gql`
  mutation DeleteNotice($id: ID!, $status: String) {
    updateNoticeBoard(input: { id: $id, status: $status }) {
      noticeBoard {
        id
      }
    }
  }
`;

export const GetResidencesQuery = gql`
  query Residence(
    $parkingArea: String
    $towerName: String
    $page: Int
    $userStatuses: [String]
  ) {
    residences(parkingArea: $parkingArea, towerName: $towerName, page: $page) {
      collection {
        id
        towerName
        flatName
        userResidences(status_code_list: $userStatuses) {
          collection {
            id
            type {
              code
            }
            admin {
              id
              name
            }
            user {
              id
              roles
              name
              contactNumber
              email
              userMedias {
                collection {
                  id
                  mediaUrl
                }
              }
            }
          }
        }
        status {
          code
        }
      }
      paginationInfo {
        lastPage
      }
    }
  }
`;

export const GetResidenceQuery = gql`
  query GetResidenceQuery($id: ID!) {
    residence(id: $id) {
      id
      parkingArea {
        name
        id
      }
      towerName
      flatName
      status {
        code
      }
      userResidences(status_code: "ACTIVE", itemsPerPage: 100) {
        collection {
          id
          type {
            code
          }
          user {
            id
            name
            email
            contactNumber
            userMedias {
              collection {
                id
                mediaUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GetCustomTowerQuery = gql`
  query residances($parkingArea: String, $itemsPerPage: Int) {
    custom_collection_queryResidences(
      parkingArea: $parkingArea
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        id
        towerName
      }
    }
  }
`;

export const CreateAreaTowerQuery = gql`
  mutation areaTower(
    $parkingArea: String!
    $towerName: String!
    $flatName: String!
  ) {
    createResidence(
      input: {
        parkingArea: $parkingArea
        towerName: $towerName
        flatName: $flatName
      }
    ) {
      residence {
        id
        towerName
        flatName
        status {
          code
        }
      }
    }
  }
`;

export const DeleteResidence = gql`
  mutation DeleteResidance($id: ID!) {
    deleteResidence(input: { id: $id }) {
      residence {
        id
      }
    }
  }
`;

export const GetVisitorsQuery = gql`
  query Visitors(
    $parkingArea: String
    $order: [ResidenceVistorLogFilter_order]
    $entryType: String
    $guard: String
    $gate: String
    $visitorContactNumber: String
    $visitorName: String
    $page: Int
    $itemsPerPage: Int
  ) {
    residenceVistorLogs(
      residenceVisitor_parkingArea: $parkingArea
      order: $order
      entryType_code: $entryType
      page: $page
      guard: $guard
      gate: $gate
      residenceVisitor_visitorContactNumber: $visitorContactNumber
      residenceVisitor_vistorName: $visitorName
      itemsPerPage: $itemsPerPage
    ) {
      paginationInfo {
        lastPage
      }
      collection {
        id
        gate {
          id
          name
        }
        visitorPhoto
        entryType {
          code
        }
        residenceVisitor {
          id
          vehicleNumber
          residence {
            id
            towerName
            flatName
          }
          residenceVistorLogs {
            collection {
              id
              createdAt
              entryType {
                code
              }
              gate {
                id
                name
              }
            }
          }
          vistorName
          visitorContactNumber
          createdAt
          residenceVisitorMedia {
            collection {
              id
              mediaUrl
            }
          }
          visitingPurpose {
            code
          }
          residenceVisitorHealths {
            collection {
              attributeType {
                code
              }
              attributeValue
            }
          }

          status {
            code
          }
        }
      }
    }
  }
`;

export const CreateVisitorQuery = gql`
  mutation CreateVisitro(
    $residence: String
    $visitorName: String!
    $visitorContactNumber: String!
    $purpose: String!
    $parkingArea: String!
    $gate: String!
    $vehicleNumber: String
  ) {
    createResidenceVistor(
      input: {
        residence: $residence
        vistorName: $visitorName
        visitorContactNumber: $visitorContactNumber
        visitingPurpose: $purpose
        parkingArea: $parkingArea
        gate: $gate
        vehicleNumber: $vehicleNumber
      }
    ) {
      residenceVistor {
        id
      }
    }
  }
`;

export const CreateVisitorHealthQuery = gql`
  mutation createVisitorHealth(
    $visitor: String!
    $attType: String!
    $attValue: String!
  ) {
    createResidenceVisitorHealth(
      input: {
        visitor: $visitor
        attributeType: $attType
        attributeValue: $attValue
      }
    ) {
      residenceVisitorHealth {
        id
        attributeType {
          code
        }
        attributeValue
      }
    }
  }
`;

export const UpdateCheckoutime = gql`
  mutation Checkout($visitor: String!, $gate: String!) {
    createResidenceVistorLog(
      input: {
        residenceVisitor: $visitor
        gate: $gate
        entryType: "/entry_types/2"
      }
    ) {
      residenceVistorLog {
        id
        createdAt
        entryType {
          code
        }
        gate {
          id
          name
        }
      }
    }
  }
`;

export const UpdateVisitorStatus = gql`
  mutation UpdateVisitorStatus($id: ID!, $status: String) {
    updateResidenceVistor(input: { id: $id, status: $status }) {
      residenceVistor {
        createdAt
        id
      }
    }
  }
`;

export const CreateParkingSlotQuery = gql`
  mutation CreateParkingSlots(
    $parkingArea: String!
    $length: Float!
    $breadth: Float!
    $height: Float!
  ) {
    createParkingBaySlot(
      input: {
        parkingArea: $parkingArea
        length: $length
        breadth: $breadth
        height: $height
      }
    ) {
      parkingBaySlot {
        id
        length
        breadth
        height
        totalSlot
      }
    }
  }
`;

export const UpdateParkingSlotQuery = gql`
  mutation UpdateParkingSlot(
    $id: ID!
    $length: Float
    $breadth: Float
    $height: Float
  ) {
    updateParkingBaySlot(
      input: { id: $id, length: $length, breadth: $breadth, height: $height }
    ) {
      parkingBaySlot {
        id
        length
        breadth
        height
        totalSlot
      }
    }
  }
`;

export const DeleteParkingSlotQuery = gql`
  mutation DeleteParkingSlot($id: ID!) {
    deleteParkingBaySlot(input: { id: $id }) {
      parkingBaySlot {
        id
      }
    }
  }
`;

export const CreateGateQury = gql`
  mutation CreateGate($name: String!, $parkingArea: String!) {
    createGate(input: { name: $name, parkingArea: $parkingArea }) {
      gate {
        id
        name
        gateManagers {
          collection {
            id
            manager {
              id
              name
              contactNumber
            }
          }
        }
      }
    }
  }
`;

export const UpdateGateQuery = gql`
  mutation UpdateGate($id: ID!, $status: String) {
    updateGate(input: { id: $id, status: $status }) {
      gate {
        id
      }
    }
  }
`;

export const CreateGateManagerQuery = gql`
  mutation CreateGateManager($gate: String!, $manager: String!) {
    createGateManager(input: { gate: $gate, manager: $manager }) {
      gateManager {
        id
        manager {
          id
          name
          contactNumber
        }
      }
    }
  }
`;

export const DeleteGateManagerQuery = gql`
  mutation DeleteGateManager($gateManager: ID!) {
    deleteGateManager(input: { id: $gateManager }) {
      gateManager {
        id
      }
    }
  }
`;

export const GetGatesQuery = gql`
  query GetGates($parkingArea: String, $gateManagers: String, $page: Int) {
    gates(
      parkingArea: $parkingArea
      page: $page
      status_code: "ACTIVE"
      gateManagers: $gateManagers
    ) {
      collection {
        id
        name
        gateManagers {
          collection {
            id
            manager {
              id
              name
              contactNumber
            }
          }
        }
      }
      paginationInfo {
        lastPage
      }
    }
  }
`;

export const GetManagerGates = gql`
  query GetGateManger($parkingArea: String, $manager: String, $page: Int) {
    gateManagers(parkingArea: $parkingArea, manager: $manager, page: $page) {
      collection {
        id
        gate {
          id
          name
        }
        manager {
          id
          name
          contactNumber
        }
      }
      paginationInfo {
        lastPage
      }
    }
  }
`;

export const GetParkingBaySlots = gql`
  query ParkingSlots($parkingArea: String) {
    parkingBaySlots(parkingArea: $parkingArea, itemsPerPage: 600) {
      collection {
        id
        length
        breadth
        height
        totalSlot
      }
    }
  }
`;

export const GetNoticesQuery = gql`
  query NoticeBoard(
    $parkingArea: String
    $status: String
    $order: [NoticeBoardFilter_order]
    $admin: String
    $page: Int
  ) {
    noticeBoards(
      parkingArea: $parkingArea
      status_code: $status
      order: $order
      admin: $admin
      page: $page
    ) {
      collection {
        id
        subject
        content
        createdAt
        admin {
          id
          name
          contactNumber
        }
      }
      paginationInfo {
        lastPage
      }
    }
  }
`;
